import { useState } from 'react';
import tw, { css, styled } from 'twin.macro';
import { MILESTONES, QUARTERS } from '../content';
import { useIntl } from 'gatsby-plugin-react-intl';
import { ExternalLinkIcon } from '@heroicons/react/solid';
import { getURL } from './navigation-ico';
import { Button } from '@ui';

const Container = styled.div`
  ${tw`relative md:flex flex-col items-center md:w-full`}
`;

const IdeaBorn = styled.div`
  ${tw`flex flex-row items-center justify-between text-white w-full max-width[1040px]`}
  height: 67px;
  background: rgba(0, 19, 38, 0.85);
  box-shadow: 0px 40px 80px rgba(0, 21, 89, 0.2);
  border-radius: 16px;
`;

const Left = styled.div`
  ${tw`flex flex-col items-end`}
`;

const Middle = styled.div`
  ${tw`flex flex-col ml-5 mt-[49px]`}
`;

const Right = styled.div`
  ${tw`flex flex-col`}
`;

const DashedLine = styled.div`
  ${tw`transform even:rotate-[32deg] odd:rotate-[-32deg] opacity[0.35]`}
  width: 2px;
  height: 95px;
`;

const MileContainer = styled.div(({ left, dot }) => [
  css`
    ${tw`flex flex-row w-full items-center self-center pl-0 lg:pl-5 z-20`}
    justify-content: ${left ? 'flex-end' : 'flex-start'};
    padding: ${dot ? (left ? '0 0 95px 0' : '95px 0 0 0') : '20px'};
  `,
]);

const MileCard = styled.div(({ primary, inactive, special, bg, btn }) => [
  css`
    background: ${bg ? bg : '#ffffff'};
    border: 1px solid rgba(116, 140, 175, 0.35);
    box-sizing: border-box;
    box-shadow: 0px 40px 80px rgba(0, 21, 89, 0.1);
    border-radius: 20px;
    height: 95px;
    min-width: ${btn ? '370px' : '222px'};
    color: ${bg ? 'white' : 'black'};

    @media (max-width: 1023px) {
      max-width: 100%;
      min-width: 100%;
      min-height: ${btn ? '190px' : '95px'};
    }
  `,
  primary &&
    css`
      background: #00a4ed;
      color: white;
    `,
  inactive &&
    css`
      opacity: 0.7;
      background: ${bg ? bg : '#fafafc'};
      border: 1px solid rgba(116, 140, 175, 0.25);
      box-shadow: none;
    `,
  tw`flex flex-col lg:flex-row justify-center lg:justify-between text-left mx-3 px-1 lg:px-0`,
  special && tw`items-stretch justify-center text-white bg-dark`,
  btn && tw`pt-3 lg:pt-0 justify-between`,
]);

const Text = styled.p`
  ${tw`text-lg font-bold uppercase p-6`}
`;

const Border = styled.div(({ color, inactive, special, btn }) => [
  {
    borderRadius: 20,
    height: 95,
    width: 10,
    backgroundColor: special ? '#F30072' : color,
    opacity: inactive ? 0.1 : 1,
  },
  css`
    @media (max-width: 1023px) {
      min-height: ${special & btn ? '190px' : '95px'};
    }
  `,
]);

const Dot = styled.div(({ color, inactive, special, left }) => [
  {
    borderRadius: 8,
    height: 16,
    width: 16,
    backgroundColor: special ? '#F30072' : color,
    opacity: inactive ? 0.1 : 1,
  },
]);

const BuyTokenBtn = styled.button`
  background: rgba(129, 0, 60, 0.3);
  border: 2px solid #f30072;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(255, 0, 77, 0.15);
  border-radius: 16px;
  padding: 15px 22px;
  margin: 4px 4px 4px 4px;
  ${tw`flex flex-col relative lg:right-1 w-full mx-auto justify-center items-center font-semibold hover:opacity-80`}

  @media (max-width: 1023px) {
    min-height: 100px;
    max-height: 100px;
    padding: 4px;
  }

  @media (min-width: 1023px) {
    height: 85px;
    padding-right: 12px;
  }
`;

const BackDropFilter = styled.div`
  background: radial-gradient(
    50% 100% at 50% 100%,
    rgba(0, 0, 0, 0.06) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  border-bottom: 1px solid rgba(0, 19, 38, 0.1);
`;

export const Milestone = ({
  title,
  date,
  bg,
  border,
  dot,
  left,
  scrollTo,
  color = '#00B1FF;',
  primary = false,
  inactive = false,
  special = false,
  btn = false,
  ...props
}) => {
  const { formatMessage, locale } = useIntl();

  const t = (key: string) => formatMessage({ id: key });

  return (
    <MileContainer
      left={left}
      dot={dot}
      special={special}
      scrollTo={scrollTo}
      {...props}
    >
      {dot ? (
        left ? null : (
          <Dot
            color={border ? border : color}
            inactive={inactive}
            left={left}
            special={special}
            btn={btn}
            tw="pr-3 md:pr-0"
          />
        )
      ) : (
        <Border
          color={border ? border : color}
          inactive={inactive}
          special={special}
          btn={btn}
          tw="pr-3 md:pr-0"
        />
      )}
      <MileCard
        bg={bg}
        special={special}
        primary={primary}
        inactive={inactive}
        btn={btn}
      >
        <div tw="flex flex-col items-start justify-center px-5">
          <h3 tw="font-bold">{t(title)}</h3>
          <p tw="font-medium text-sm">{t(date)}</p>
        </div>
        {special &&
          (btn ? (
            <a
              href={getURL() + '/' + locale + '/launchpad/log-in'}
              id="click-gtag-roadmap-buy-token"
            >
              <BuyTokenBtn>
                <ExternalLinkIcon tw="h-6 w-6" /> Buy Token
              </BuyTokenBtn>
            </a>
          ) : null)}
      </MileCard>
      {dot && left && (
        <Dot
          color={border ? border : color}
          inactive={inactive}
          left={left}
          special={special}
          btn={btn}
          tw="pr-3 md:pr-0"
        />
      )}
    </MileContainer>
  );
};

export const RoadMap = () => {
  const [flag, setFlag] = useState(false);
  const { formatMessage } = useIntl();

  const handleVisibility = () => {
    setFlag(!flag);
    flag &&
      document
        .getElementById('scroll-to-tilee')
        .scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return (
    <Container tw="relative">
      <h1 tw="text-7xl text-grey font-bold mb-20">
        Road<span tw="text-black">map.</span>
      </h1>
      <IdeaBorn>
        <Text>{formatMessage({ id: 'roadmap.dates.genesis.title' })}</Text>
        <Text tw="font-normal normal-case">
          {formatMessage({ id: 'roadmap.dates.genesis.date' })}
        </Text>
      </IdeaBorn>

      <div
        tw="flex flex-row mt-20 overflow-hidden pb-10"
        style={{
          maxHeight: flag ? '100%' : 800,
        }}
      >
        <Left>
          {MILESTONES.map(
            (item, idx) =>
              item.left && (
                <Milestone
                  special={item.special}
                  title={item.title}
                  date={item.date}
                  left={item.left}
                  inactive={item.inactive}
                  border={item.border}
                  dot={true}
                  bg={item.bg}
                  btn={false}
                  key={idx}
                />
              )
          )}
        </Left>
        <Middle>
          {[...Array(21).keys()].map((_, idx) => (
            <DashedLine key={idx}>
              <div tw="w-0.5 h-3 bg-[rgba(116, 140, 175, 1)]"></div>
              <div tw="h-2"></div>
              <div tw="w-0.5 h-3 bg-[rgba(116, 140, 175, 1)]"></div>
              <div tw="h-2"></div>
              <div tw="w-0.5 h-3 bg-[rgba(116, 140, 175, 1)]"></div>
              <div tw="h-2"></div>
              <div tw="w-0.5 h-3 bg-[rgba(116, 140, 175, 1)]"></div>
              <div tw="h-2"></div>
              <div tw="w-0.5 h-3 bg-[rgba(116, 140, 175, 1)]"></div>
            </DashedLine>
          ))}
        </Middle>
        <Right>
          {MILESTONES.map(
            (item, idx) =>
              !item.left && (
                <Milestone
                  special={item.special}
                  title={item.title}
                  date={item.date}
                  left={item.left}
                  inactive={item.inactive}
                  border={item.border}
                  dot={true}
                  bg={item.bg}
                  btn={false}
                  key={idx}
                  id={item.scrollTo ? 'scroll-to-tilee' : null}
                />
              )
          )}
        </Right>
      </div>
      <BackDropFilter tw="absolute bottom[104px] w-full h-20 max-width[1040px]"></BackDropFilter>
      {flag ? (
        <Button
          tw="w-full bg-primary mt-10 font-weight[500] text-white md:w-60 rounded-full"
          primary
          onClick={() => handleVisibility()}
        >
          {formatMessage({ id: 'roadmap.hideBtn' })}
        </Button>
      ) : (
        <Button
          tw="w-full bg-primary mt-10 font-weight[500] text-white md:w-60 rounded-full"
          primary
          onClick={() => handleVisibility()}
        >
          {formatMessage({ id: 'roadmap.showBtn' })}
        </Button>
      )}
    </Container>
  );
};
